import React from 'react';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	Button,
} from '@components';
import { Modal } from '@dev2t/react-ui-kit/src/components';
import { ModalPopups } from '@enums';
import { app } from '@redux';

const styles = theme => ({
	title: {
		...theme.customstyles.subheading,
		color: theme.palette.text.primary,
		wordWrap: 'break-word',
	},
	content: {
		textAlign: 'center',
	},
	body: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
		marginTop: '16px',
		wordWrap: 'break-word',
	},
	button: {
		paddingTop: '12px',
	},
});

const mapStateToProps = state => ({
	confirmModalData: app.selectors.confirmModalData(state),
});

const mapDispatchToProps = dispatch => ({
	actions: {
		hide: () => dispatch(app.actions.hideConfirmModal()),
	},
});

class ConfirmModal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { submitting: false };
	}

	submit = () => {
		this.setState({ submitting: true });
		if (this.props.confirmModalData && this.props.confirmModalData.action) {
			this.props.confirmModalData.action(this.props.actions.hide);
		}
	}

	render() {
		const {
			classes,
			onClose,
			confirmModalData: {
				title,
				text,
				buttonTitle,
			},
			theme,
			resolution
		} = this.props;

		const btnTitle = buttonTitle || 'Confirm';

		const isXsResolution = resolution === app.CONFIG.RESOLUTIONS_ENUM.xs;

		return (
			<Modal
				title={title || 'Confirmation'}
				onClose={onClose}
				className={`QA-${ModalPopups.CONFIRM_MODAL}`}
				fullScreen={isXsResolution}
			>
				<div className={classes.content}>
					{text || `Click on "${btnTitle}" to confirm the request.`}
					<div className={classes.button}>
						<Button
							color="primary"
							variant="contained"
							fullWidth
							onClick={this.submit}
							disabled={this.state.submitting}
						>
							{this.state.submitting ?
								<CircularProgress
									style={{ color: theme.palette.common.white }}
									size={18}
								/>
								: btnTitle
							}
						</Button>
					</div>
				</div>
			</Modal>
		);
	}
}

ConfirmModal.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	actions: PropTypes.object.isRequired,
	confirmModalData: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, mapDispatchToProps),
)(ConfirmModal);
