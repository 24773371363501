import { put, fork, select, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
	accountDetails,
	accountSelect,
	achLinking,
	getLinkedAccounts,
	wireLinking,
	achUnlinkBankAccount,
	wireUnlinkBankAccount,
} from '@api';
import { constants, env } from '@config';
import { ModalPopups } from '@enums';
import { captureException, changeHashInUrl, removeHashesFromUrl, captureError } from '@global';
import { BaseGetSagaHandler, callApi, snackbar } from '@redux';

import actions from './actions';
import { isUnauthorized } from '../sagaExtensions';


export function* getLinkedAccountsRequestSaga({ payload }) {
	try {
		const request = yield callApi(getLinkedAccounts, payload);
		if (!request.data.Success) {
			yield put(actions.getLinkedAccounts.failure(request.data.Errors[0].Message));
			if (!isUnauthorized(request)) {
				yield put(snackbar.actions.showErrorMessage({
					title: 'Get Cash Management',
					text: request.data.Errors[0].Message,
				}));
			}
		} else {
			if (request.data.LinkedAccounts && request.data.LinkedAccounts.length > 0) {
				request.data.LinkedAccounts = request.data.LinkedAccounts.map(item => ({
					...item,
					displayNumber: (item.Number && item.Number.length > 3)
						? item.Number.slice(item.Number.length - 4)
						: item.Number,
				}));
			}
			yield put(actions.getLinkedAccounts.success(request.data));
			return request.data;
		}
	} catch (error) {
		captureException('getLinkedAccountsRequestSaga', error);
		yield put(snackbar.actions.showErrorMessage({ title: 'Get Cash Management', text: error.message }));
		yield put(actions.getLinkedAccounts.failure(error.message));
		console.error(error);
	}
	return null;
}

function* getPlaidAccounts({ payload: { tradeCode, token } }) {
	try {
		// get current location
		const location = yield select(state => state.router.location);
		// show modal
		yield put(push(changeHashInUrl(location, ModalPopups.ADD_BANK_ACCOUNT, ModalPopups.PLAID_SELECT_ACCOUNT)));

		const request = yield callApi(accountDetails, tradeCode, token);
		if (!request.data.Success) {
			yield put(actions.getPlaidAccountsFailure(request.data.Errors[0].Message));
		} else {
			yield put(actions.getPlaidAccountsSuccess(request.data));
		}
	} catch (error) {
		captureError(error);
		yield put(actions.getPlaidAccountsFailure('An error was occured.'));
	}
}

function* plaidSelectAccountFormSubmit({ payload: { TradeCode, LinkAccountNo } }) {
	try {
		const request = yield callApi(accountSelect, TradeCode, LinkAccountNo);
		if (!request.data.Success) {
			yield put(actions.plaidSelectAccountFormFailure(request.data.Errors[0].Message));
			yield put(snackbar.actions.showErrorMessage({ text: request.data.Errors[0].Message }));
		} else {
			yield put(actions.plaidSelectAccountFormSuccess());
			yield put(actions.getLinkedAccounts.request({ tradeCode: TradeCode }));
		}
	} catch (error) {
		captureError(error);
		yield put(actions.plaidSelectAccountFormFailure(error.message));
		yield put(snackbar.actions.showErrorMessage({ text: error.message }));
	}
}

function* achManualLinkingFormSubmit({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: achLinking,
		errorText: 'ACH Manually Link Bank Account',
		handler: actions.achManualLinkingForm,
	}, { payload });

	if (isSuccess) {
		yield put(actions.getLinkedAccounts.request({ tradeCode: payload.TradeCode, submitting: true }));
	}
}

function* hideAddBankAccountModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(removeHashesFromUrl(location, [ModalPopups.ADD_BANK_ACCOUNT, ModalPopups.PLAID_SELECT_ACCOUNT])));
}
function* showPlaidSelectAccountModal() {
	// get current location
	const location = yield select(state => state.router.location);
	// show modal
	yield put(push(changeHashInUrl(location, ModalPopups.ADD_BANK_ACCOUNT, ModalPopups.PLAID_SELECT_ACCOUNT)));
}

export function MapWireLinkPayload(payload) {
	let result = {
		...payload.wireLink,
		Name: payload.wireLink.ClearingFirm === 'cor' ? payload.wireLink.Name : 'Name',
		Surname: payload.wireLink.ClearingFirm === 'cor' ? payload.wireLink.Surname : 'Surname',
		Address: payload.wireLink.ClearingFirm === 'cor' ? payload.wireLink.Address : 'Address',
		ZipCode: payload.wireLink.ClearingFirm === 'cor' ? payload.wireLink.ZipCode : 'ZipCode',
		Phone: payload.wireLink.ClearingFirm === 'cor' ? payload.wireLink.Phone : '123456789',
		BankRoutingNumber: payload.wireLink.WireType === 'International' ? '123456789' : payload.wireLink.BankRoutingNumber,
		BankSwiftCode: payload.wireLink.WireType === 'Domestic' ? '123456789' : payload.wireLink.BankSwiftCode,
		UseIntermediaryBank: payload.wireLink.UseIntermediaryBank ? 'FME' : false,
		IntermediaryBankRoutingNumber:
			(payload.wireLink.UseIntermediaryBank && payload.wireLink.WireType === 'International') ? '123456789' : payload.wireLink.IntermediaryBankRoutingNumber,
		IntermediaryBankSwiftCode:
			(payload.wireLink.UseIntermediaryBank && payload.wireLink.WireType === 'Domestic') ? '123456789' : payload.wireLink.IntermediaryBankSwiftCode,
	};

	
	if (result.UseIntermediaryBank === 'FME') {
		result.UseIntermediaryBank = true;
	}

	return result;
}

function* wireManualLinkingFormSubmit({ payload }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: wireLinking,
		errorText: 'WIRE Link Bank Account',
		handler: actions.wireManualLinkingForm,
	}, {
		payload: MapWireLinkPayload(payload),
	});

	if (isSuccess) {
		yield put(actions.getLinkedAccounts.request({ tradeCode: payload.wireLink.TradeCode, submitting: true }));
	}
}

function* achUnlinkBankAccountSaga({ payload: { onEndRequest, ...others } }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: achUnlinkBankAccount,
		errorText: 'ACH Unlink Bank Account',
		handler: actions.achUnlinkBankAccount,
		onEndRequest,
	}, { payload: others });

	if (isSuccess) {
		yield put(actions.getLinkedAccounts.request({ tradeCode: others.tradeCode, submitting: true }));
	}
}

function* wireUnlinkBankAccountSaga({ payload: { onEndRequest, ...others } }) {
	const isSuccess = yield BaseGetSagaHandler({
		apiMethod: wireUnlinkBankAccount,
		errorText: 'WIRE Unlink Bank Account',
		handler: actions.wireUnlinkBankAccount,
		onEndRequest,
	}, { payload: others });

	if (isSuccess) {
		yield put(actions.getLinkedAccounts.request({ tradeCode: others.tradeCode, submitting: true }));
	}
}


// Watchers
function* watchGetLinkedAccountsRequest() {
	yield takeEvery(actions.getLinkedAccounts.REQUEST, getLinkedAccountsRequestSaga);
}
function* watchPlaidAccounts() {
	yield takeEvery(actions.GET_PLAID_ACCOUNTS_REQUEST, getPlaidAccounts);
}
function* watchPlaidSelectAccountFormSubmit() {
	yield takeEvery(actions.PLAID_SELECT_ACCOUNT_FORM_SUBMIT_REQUEST, plaidSelectAccountFormSubmit);
}
function* watchAchManualLinkingFormSubmit() {
	yield takeEvery(actions.achManualLinkingForm.REQUEST, achManualLinkingFormSubmit);
}
function* watchWireManualLinkingFormSubmit() {
	yield takeEvery(actions.wireManualLinkingForm.REQUEST, wireManualLinkingFormSubmit);
}

function* watchHideAddBankAccountModal() {
	yield takeEvery([
		actions.PLAID_SELECT_ACCOUNT_FORM_SUBMIT_SUCCESS,
		actions.achManualLinkingForm.SUCCESS,
		actions.wireManualLinkingForm.SUCCESS,
	], hideAddBankAccountModal);
}
function* watchShowPlaidSelectAccountModal() {
	yield takeEvery([
		actions.GET_PLAID_ACCOUNTS_SUCCESS,
		actions.GET_PLAID_ACCOUNTS_FAILURE,
	], showPlaidSelectAccountModal);
}

function* watchAchUnlinkBankAccountRequest() {
	yield takeEvery(actions.achUnlinkBankAccount.REQUEST, achUnlinkBankAccountSaga);
}
function* watchWireUnlinkBankAccountRequest() {
	yield takeEvery(actions.wireUnlinkBankAccount.REQUEST, wireUnlinkBankAccountSaga);
}


// Root saga
const rootSaga = [
	fork(watchGetLinkedAccountsRequest),
	fork(watchPlaidAccounts),
	fork(watchPlaidSelectAccountFormSubmit),
	fork(watchAchManualLinkingFormSubmit),
	fork(watchHideAddBankAccountModal),
	fork(watchShowPlaidSelectAccountModal),
	fork(watchWireManualLinkingFormSubmit),
	fork(watchAchUnlinkBankAccountRequest),
	fork(watchWireUnlinkBankAccountRequest),
];

export default {
	rootSaga,
};
