import React from 'react';
import PropTypes from 'prop-types';
import { LogoIcon, ListItemContainer } from '@components';
import { useMoneyFormat } from '@hooks';

const Mt5GlobalCurrencyItem = ({ currencyBalanceItem }) => {
	const moneyFormat = useMoneyFormat({ precision: 8, disableCurrencyCode: true });
	return (
		<ListItemContainer divider>
			<LogoIcon currency={currencyBalanceItem.CurrencyCode} />
			<div>{moneyFormat(currencyBalanceItem.Balance, currencyBalanceItem.CurrencyCode)}</div>
		</ListItemContainer>
	);
};

Mt5GlobalCurrencyItem.propTypes = {
	currencyBalanceItem: PropTypes.shape({
		CurrencyCode: PropTypes.string.isRequired,
		Balance: PropTypes.number.isRequired,
	}).isRequired,
};

export default Mt5GlobalCurrencyItem;
