import React, { useState } from 'react';
import {
	SuccessMessage,
	ErrorMessage,
} from '@components';
import { R, Modal } from '@dev2t/react-ui-kit/src';

import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { userAccounts } from '@redux';
import { CYChangeMtPasswordForm } from '@shared';

const useStyles = makeStyles(theme => ({
	requirement: {
		color: theme.palette.text.secondary,
		padding: '0 12px',
		'&:last-of-type': {
			marginBottom: '30px',
		},
	},
}));

const CYChangeMtPasswordModal = ({ onClose }) => {
	const classes = useStyles();
	const [step, setStep] = useState('openform');

	const currentAccount = useSelector(userAccounts.selectors.accountInfo);

	const title = (
		<span>
			<R id="common_password_change" defaultValue="Password change" />
			{' '}
			{currentAccount?.TradeCode}
			{' - '}
			{currentAccount?.TradeLogin}
		</span>
	);

	return (
		<Modal
			title={title}
			onClose={onClose}
			maxWidth="md"
		>
			<div>
				{step === 'succeeded' &&
					<SuccessMessage
						onClick={onClose}
						text={<R id="common_password_change_success" defaultValue="Password has been changed successfully" />}
					/>
				}
				{step === 'failed' &&
					<ErrorMessage
						onClick={onClose}
						text={<R id="common_could_not_change_password" defaultValue="Could not change password" />}
					/>
				}
				{step === 'openform' &&
					<CYChangeMtPasswordForm
						enableReinitialize
						forceUnregisterOnUnmount
						onSubmit={userAccounts.actions.changeMtPassword}
						onSubmitSuccess={() => setStep('succeeded')}
						onSubmitFail={() => setStep('failed')}
					/>
				}
			</div>
		</Modal>
	);
};

export default CYChangeMtPasswordModal;
