/* eslint-disable max-len */
import React from 'react';
import {
	makeStyles,
	useTheme,
} from '@material-ui/core/styles';
import { env } from '@config';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import {
	dashboard,
	userAccounts,
} from '@redux';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import { SvgIcon, ListItem, useTranslate } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { ModalPopups, AccountType } from '@enums';
import { useAccount } from '@hooks';
import { addHashToUrl, scrollToTop } from '@global';

const useStyles = makeStyles(theme => ({
	root: {},
	listItemText: {
		...theme.customstyles.body,
	},
}));

const useActions = () => {
	const dispatch = useDispatch();
	return {
		setCurrentPage: page => dispatch(push(page)),
		sidebarClose: () => dispatch(dashboard.actions.sidebarClose()),
	};
};

const AccountActions = (props) => {

	const hasMt5Account = useAccount(AccountType.MT5).length > 0;
	const hasWalletAccount = useAccount(AccountType.WALLET).length > 0;
	const userInfo = useSelector(userAccounts.selectors.userInfo);
	const classes = useStyles();
	const theme = useTheme();
	const translate = useTranslate();

	const actions = useActions();

	const showPage = path => () => {
		actions.setCurrentPage(path);
		scrollToTop();
		actions.sidebarClose();
	};

	const showModal = (id) => {
		props.history.push(addHashToUrl(props.location, id));
	};

	return (
		<div>
			<List>
				{userInfo.IsPreopened && (
					<ListItem button gaUrl="/welcome/" onClick={() => showModal(ModalPopups.WELCOME_PAGE)}>
						<ListItemIcon>
							<SvgIcon
								icon="Star"
								htmlColor={theme.palette.primary.main}
							/>
						</ListItemIcon>
						<ListItemText
							className={classes.listItemText}
							primary="Welcome Page"
							disableTypography
						/>
					</ListItem>
				)}
				<>
					<ListItem button onClick={showPage('/dashboard')}>
						<ListItemIcon>
							<SvgIcon
								icon="Dashboard"
								htmlColor={theme.palette.primary.main}
							/>
						</ListItemIcon>
						<ListItemText
							className={classes.listItemText}
							primary={<R id="shared_dashboard" defaultValue="Dashboard" />}
							disableTypography
						/>
					</ListItem>
					<ListItem 
						button 
						onClick={showPage(hasMt5Account ? '/dashboard/mt5global' : '/dashboard/openmt5global')}
					>
						<ListItemIcon>
							<SvgIcon
								icon="Wallet"
								htmlColor={theme.palette.primary.main}
							/>
						</ListItemIcon>
						<ListItemText
							className={classes.listItemText}
							primary={<R id="trade_account" defaultValue="Trade Account" />}
							disableTypography
						/>
					</ListItem>
					{hasWalletAccount && (
						<ListItem button onClick={showPage('/dashboard/wallet')}
						>
							<ListItemIcon>
								<SvgIcon
									icon="Wallet"
									htmlColor={theme.palette.primary.main}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.listItemText}
								primary={translate('shared_wallet', 'Wallet')}
								disableTypography
							/>
						</ListItem>
					)}
					<ListItem button onClick={showPage('/dashboard/staking')}>
						<ListItemIcon>
							<SvgIcon
								icon="Staking"
								htmlColor={theme.palette.primary.main}
							/>
						</ListItemIcon>
						<ListItemText
							className={classes.listItemText}
							primary={translate('shared_staking', 'Staking')}
							disableTypography
						/>
					</ListItem>
					<ListItem button onClick={showPage('/dashboard/loans')} disabled>
						<ListItemIcon>
							<SvgIcon
								icon="Loans"
								htmlColor={theme.palette.primary.main}
							/>
						</ListItemIcon>
						<ListItemText
							className={classes.listItemText}
							primary={`${translate('shared_loans', 'Loans')} (${translate('shared_soon', 'Soon')})`}
							disableTypography
						/>
					</ListItem>
					<ListItem button onClick={showPage('/dashboard/exchange')} disabled>
						<ListItemIcon>
							<SvgIcon
								icon="Exchange"
								htmlColor={theme.palette.primary.main}
							/>
						</ListItemIcon>
						<ListItemText
							className={classes.listItemText}
							primary={`${translate('shared_exchange', 'Exchange')} (${translate('shared_soon', 'Soon')})`}
							disableTypography
						/>
					</ListItem>
					<ListItem button onClick={showPage('/dashboard/settings')}>
						<ListItemIcon>
							<SvgIcon
								icon="Person"
								htmlColor={theme.palette.primary.main}
							/>
						</ListItemIcon>
						<ListItemText
							className={classes.listItemText}
							primary={translate('common_menu_my_profile', 'My Profile')}
							disableTypography
						/>
					</ListItem>
					{env.WEBCAB_8523_showDisclosures && (
						<ListItem button onClick={showPage('/dashboard/disclosures')}>
							<ListItemIcon>
								<ErrorIcon 
									htmlColor={theme.palette.primary.main}
								/>
							</ListItemIcon>
							<ListItemText
								className={classes.listItemText}
								primary={translate('common_menu_disclosures', 'Disclosures')}
								disableTypography
							/>
						</ListItem>
					)}
					{hasMt5Account && (
					<ListItem button onClick={showPage('/dashboard/apikeys')}>
						<ListItemIcon>
							<VpnKeyOutlinedIcon
								htmlColor={theme.palette.primary.main}
							/>
						</ListItemIcon>
						<ListItemText
							className={classes.listItemText}
							primary={translate('common_menu_api_management', 'API Management')}
							disableTypography
						/>
					</ListItem>)}
				</>
			</List>
		</div>
	);
};

AccountActions.propTypes = {

};

export default withRouter(AccountActions);
