import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userAccounts } from '@redux';
import { AccountType } from '@enums';

const mockStakingAccount = {
	TradeCode: 'J2TX_STAKING_MOCK_TRADECODE',
	IsStaking: true,
	DisplayFullName: "J2TX Staking",
}

export function useAccount(filter) {
	const accounts = useSelector(userAccounts.selectors.accounts);

	const acc = accounts.filter(x => x.TradeCode === filter);
	if (acc.length === 1) return acc[0];
	if (filter === 'J2TX_STAKING_MOCK_TRADECODE') return mockStakingAccount;

	const mt5Accounts = useMemo(() => (accounts && accounts.filter(x => x.IsMT5)) || [], [accounts]);
	const walletAccounts = useMemo(() => (accounts && accounts.filter(x => x.IsWallet)) || [], [accounts]);
	const stakingAccounts = useMemo(() => {
		const stakingAccs = accounts?.filter(x => x.IsStaking) ?? [];
		if (!stakingAccs.length) stakingAccs.push(mockStakingAccount)
		return stakingAccs;
	}, [accounts]);

	if (filter === AccountType.ALL) return accounts ?? [];
	if (filter === AccountType.WALLET) return walletAccounts;
	if (filter === AccountType.MT5) return mt5Accounts;
	if (filter === AccountType.STAKING) return stakingAccounts;
	throw new Error('use AccountType from enum or tradeCode');
}
