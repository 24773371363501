import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	reduxForm,
	Field,
} from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';

import {
	FormSelect,
	Divider,
	Button,
	withTranslate,
} from '@components';
import { R, Input } from '@dev2t/react-ui-kit/src/components';
import { nontradingOrders, userAccounts, app } from '@redux';
import { RequireConfirmationCodeItem } from '@shared';
import { template } from '@templates';

const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		marginTop: '30px',
		paddingLeft: '50px',
	},
	item: {
		marginTop: '30px',
		paddingLeft: '74px',
	},
	divider: {
		marginTop: '30px',
	},
	fileUploader: {
		marginTop: '16px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	btContainer: {
		marginTop: '48px',
	},
});

const AvailableLeverages = [50, 100, 200, 500, 1000, 2000];

const CY_NON_TRADING_ORDER_LEVERAGE_FORM_ID = 'CYNonTradingOrderLeverageForm';
const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const currentAccountDisplayFullName = userAccounts.selectors.currentAccountDisplayFullName(state);
	const language = app.selectors.language(state);
	
	const currentUserAccountsInfo = userAccounts.selectors.accountInfo(state);
	const leverageText = currentUserAccountsInfo && currentUserAccountsInfo.LeverageText;
	return {
		initialValues: {
			
			TradeCode: currentTradeAccount.tradeCode,
			ClearingFirm: currentTradeAccount.clearerFirm,
		},
		currentTradeAccount,
		currentAccountDisplayFullName,
		leverageText,
		language,
	};
};


class CYNonTradingOrderLeverageForm extends React.Component {

	static validate(formvalues, props) {

		const values = formvalues;
		const errors = {};

		if (!values) {
			return errors;
		}

		if (!values.Leverage) {
			errors.Leverage = 'required';
		}

		/* if (!values.ConfirmationCode) {
			errors.ConfirmationCode = 'required';
		} */
		return errors;
	}

	render() {
		const {
			classes,
			fromUserAccount,
			handleSubmit,
			submitSucceeded,
			theme,
			currentTradeAccount,
			currentAccountDisplayFullName,
			leverageText,
			translate,
			...props
		} = this.props;

		return (
			<form
				onSubmit={handleSubmit(nontradingOrders.actions.sendNonTradingOrderLeverageForm)}
				className={classes.root}
			>
				<div className={classes.accountContainer}>
					<div className={classes.row}>
						<div className={classes.logo}>
							<template.firmLogo />
						</div>
						<Field
							component={FormSelect}
							name="TradeCode"
							label={translate('common_account', 'Account')}
							disabled
						>
							<MenuItem value={currentTradeAccount.tradeCode}>
								<div className={classes.inputContainer}>
									<div className={classes.account}>{currentAccountDisplayFullName}</div>
								</div>
							</MenuItem>
						</Field>
					</div>
				</div>
				<div className={classes.divider}>
					<Divider />
				</div>
				<div className={classes.inputItem}>
					<Input
						label={translate('field_leverage_current', 'Current leverage')}
						value={leverageText || '-'}
						disabled
					/>
				</div>
				<div className={classes.item}>
					<Field
						component={FormSelect}
						name="Leverage"
						label={translate('field_leverage_new', 'Choose new leverage')}
					>
						{AvailableLeverages.map((leverageVal, index) => (
							<MenuItem value={leverageVal} key={index}>
								<div className={classes.inputContainer}>
									{`1 : ${leverageVal}`}
								</div>
							</MenuItem>
						))}
					</Field>
				</div>
				<div className={classes.btContainer}>
					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={props.invalid || props.submitting}
					>
						{props.submitting ?
							<CircularProgress
								style={{ color: theme.palette.common.white }}
								size={18}
							/>
							: <R id="leverage_order_submit_button" defaultValue="Change Leverage" />
						}
					</Button>
				</div>
			</form>
		);
	}
}

CYNonTradingOrderLeverageForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
	currentAccountDisplayFullName: PropTypes.string.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	withTranslate,
	connect(mapStateToProps, null),
	reduxForm({
		form: CY_NON_TRADING_ORDER_LEVERAGE_FORM_ID,
		validate: CYNonTradingOrderLeverageForm.validate,
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
	}),
)(CYNonTradingOrderLeverageForm);
