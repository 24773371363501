
import { withStyles } from '@material-ui/core/styles';
import { ProgressContainer, ShowMore, CYItem } from '@components';

import { OperationType } from '@enums';
import { userAccounts } from '@redux';

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import compose from 'recompose/compose';
import moment from 'moment';

import OperationsTableFilter from './OperationsTableFilter';


const styles = (theme) => {
	return {
		itemContainer: {
			padding: '0 16px',
			margin: '0px -16px',
			'&:hover': {
				backgroundColor: theme.palette.customColors.hover,
			},
		},
		item: {
			borderBottom: '1px solid #deeaef',
			padding: '16px 0',
		}
	};
};


const OperationsList = ({classes, selector, action, comboBoxOptions}) => {

	const reloadData = (payload) => {
		dispatch(action(payload))
	};
	
	const loadMore = () => {
		payload.Page++;
		reloadData(payload)
	}
	
	const onOperationTypeChange = (newType) => {		
		payload.Type = newType;
		payload.Page = 1;
		reloadData(payload);
	};
	
	const onStartDateChange = (newStartDate) => {
		payload.StartDate = moment(newStartDate).format('YYYYMMDD');
		payload.Page = 1;
		reloadData(payload);
	};
	
	const onEndDateChange = (newEndDate) => {
		payload.EndDate = moment(newEndDate).format('YYYYMMDD');
		payload.Page = 1;
		reloadData(payload);
	};

	const dispatch = useDispatch();
	const activities = useSelector(selector);
	const currentTradeAccount = useSelector(userAccounts.selectors.currentTradeAccount);
	
	const payload = {
		TradeCodes: [currentTradeAccount.tradeCode],
		StartDate: moment(activities.dates.StartDate).format('YYYYMMDD'),
		EndDate: moment(activities.dates.EndDate).format('YYYYMMDD'),
		Page: activities.pagination.Page,
		Type: activities.type || OperationType.ALL,
	}

	return (
		<React.Fragment>
			<OperationsTableFilter
				operationType={activities.type || OperationType.ALL}
				onOperationTypeChange={onOperationTypeChange}
				
				startDate={activities.dates.StartDate}
				onStartDateChange={onStartDateChange}
				
				endDate={activities.dates.EndDate}
				onEndDateChange={onEndDateChange}
 
				selectFields={comboBoxOptions}
			/>

			<ProgressContainer submitting={activities.loading}>
				{!activities.loading
				&& activities.items.map((item, index) => (
					<div className={classes.itemContainer} key={index}>
						<div className={classes.item}>
							<CYItem item={item} />
						</div>				
					</div>
				))}

				<ShowMore
					submitting={activities.loading && activities.items && activities.items.length > 0}
					onClick={loadMore}
					showMoreButton={activities.pagination.Page * activities.pagination.PageSize <= activities.items.length}
				/>
			</ProgressContainer>
		</React.Fragment>
	);
}

export default compose(
	withStyles(styles),
)(OperationsList);
