
import { useBalance } from '@hooks';

export default function getAvailableCurrencies(sourceAccount, destinationAccount) {
	
	if (!sourceAccount || !destinationAccount) return [];

	const sourceBalance = useBalance(sourceAccount.TradeCode);

	if (!sourceBalance) return [];
		
	if (destinationAccount.IsMt5) {
		// если переводим на мт5 usd то надо убрать eur из списка валют
		// если переводим на мт5 eur то надо убрать usd из списка валют
		const destinationAccountCurrency = destinationAccount.Platform && destinationAccount.Platform.split(':').pop();
		const currencyToRemove = destinationAccountCurrency === 'USD' ? 'EUR' : 'USD';
		
		return sourceBalance
			.filter(x => x.CurrencyCode !== currencyToRemove)
			.filter(x => x.IsAllowedTransfer)
			.map(x => x.CurrencyCode);
	}
	else if (destinationAccount.IsStaking) {
		
		// если переводим на стейкинг => только валюты доступные для стейкинга
		return sourceBalance
			.filter(x => x.IsAllowedStaking)
			.map(x => x.CurrencyCode);
	}
	else {

		// в остальных случаях => только валюты доступные для перевода
		return sourceBalance
			.filter(x => x.IsAllowedTransfer)
			.map(x => x.CurrencyCode);
	}
}
