import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { SuccessMessage, ErrorMessage, ProgressContainer } from '@components';
import { R, Modal } from '@dev2t/react-ui-kit/src/components';
import { ordersCy, userAccounts } from '@redux';
import {
	CYWithdrawalCryptoWalletSelectMethod,
	CYWithdrawalCryptoWalletAmountForm,
	CYWithdrawalCryptoWalletConfirmForm,
} from './components';

const CY_WITHDRAWAL_CRYPTOWALLET_FORM_ID = 'CYWithdrawalCryptoWalletForm';
const CYWithdrawalCryptoWalletModal = ({ onClose }) => {

	const [step, setStep] = useState('selectmethod');
	const loading = useSelector(state => userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading);

	const handleGotoPage = page => (result, dispatch, props) => setStep(page);

	return (
		<Modal
			title={<R id="shared_withdrawal_funds" defaultValue="Withdrawal Funds" />}
			onClose={onClose}
			maxWidth="sm"
		>
			<ProgressContainer submitting={loading}>
				<div>
					{step === 'succeeded' &&
						<SuccessMessage
							onClick={onClose}
							text={<R id="common_crypto_wallet_completed" defaultValue="Withdrawal order completed" />}
						/>
					}
					{step === 'failed' &&
						<ErrorMessage
							onClick={onClose}
							text={<R id="common_crypto_wallet_failed" defaultValue="Could not complete order" />}
						/>
					}
					{step === 'selectmethod' &&
						<CYWithdrawalCryptoWalletSelectMethod
							destroyOnUnmount={false}
							forceUnregisterOnUnmount
							form={CY_WITHDRAWAL_CRYPTOWALLET_FORM_ID}
							enableReinitialize
							onSubmit={handleGotoPage('amountform')}
							onSuccess={() => setStep('amountform')}
							onSubmitFail={() => setStep('failed')}
						/>
					}
					{step === 'amountform' &&
						<CYWithdrawalCryptoWalletAmountForm
							destroyOnUnmount={false}
							//forceUnregisterOnUnmount
							form={CY_WITHDRAWAL_CRYPTOWALLET_FORM_ID}
							//enableReinitialize
							onSubmit={handleGotoPage('confirmform')}
							onSuccess={() => setStep('confirmform')}
							onSubmitFail={() => setStep('failed')}
						/>
					}
					{step === 'confirmform' &&
						<CYWithdrawalCryptoWalletConfirmForm
							//enableReinitialize
							//forceUnregisterOnUnmount
							form={CY_WITHDRAWAL_CRYPTOWALLET_FORM_ID}
							onSubmit={ordersCy.actions.cyWithdrawalEmoneyForm}
							onSuccess={() => setStep('succeeded')}
							onSubmitFail={() => setStep('failed')}
						/>
					}
				</div>
			</ProgressContainer>
		</Modal>
	);
};

export default CYWithdrawalCryptoWalletModal;
