import React, { useEffect, useMemo, useState  } from 'react';
import moment from 'moment';
import { userAccounts } from '@redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import NoDataIcon from '@material-ui/icons/NotInterested';
import { useTranslate, ContentCloud, ShowMore } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import {useMoneyFormat, useAccount } from '@hooks';
import { OperationType, AccountType } from '@enums';
import { LogoIcon } from '@components';

const useStyles = makeStyles(theme => ({
	headCell: {
		paddingTop: 0,
		paddingBottom: 0,
		border: 'none',
		fontSize: '12px',
		lineHeight: 1.5,
		verticalAlign: 'top',
		boxSizing: 'border-box',
		[theme.breakpoints.down('sm')]: {
			'&:first-of-type': {
				paddingLeft: 0,
			},
		},
	},
	noDataCell: {
		border: 'none',
		paddingBottom: 0,
	},
	open: {
		padding: '0 50px 32px',
		[theme.breakpoints.down('sm')]: {
			padding: '0 40px 20px',
		},
	},
	content: {
		overflowX: 'auto',
	},
}));

const MyTableRow = withStyles(theme => ({
	root: {
		height: '65px',
		borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
		'&:last-child': {
			borderBottom: 'none',
		},
	},
}))(TableRow);

const MyTableCell= withStyles(theme => ({
	root: {
		borderBottom: 'none',
	},
}))(TableCell);

const J2txWalletTransactionsHistory = () => {
    const translate = useTranslate();
	const dispatch = useDispatch();
	const classes = useStyles();

    const walletAccounts = useAccount(AccountType.WALLET);
    const currentWalletAccount = walletAccounts[0]; // На момент написания аккаунт может быть только 1
	const cashActivities = useSelector(userAccounts.selectors.cashActivities);
	const moneyFormat = useMoneyFormat({ precision: 8 });
	const theme = useTheme();

    const walletsTransactionsHistory = useMemo(
        () => cashActivities?.items.map(x => {
            return {
                ...x,
                Amount: currentWalletAccount.TradeCode === x.TradeCode ? -x.Amount : x.Amount
            }
	    }) ?? [],
        [cashActivities]
    );
	
	const [isOpen, setIsOpen] = useState(true);
	const onToggleOpen = () => setIsOpen(open => !open);

	const loadTransactions = (loadMore) => {
		if (!currentWalletAccount) return;
        const page = loadMore ? cashActivities.pagination.Page + 1 : cashActivities.pagination.Page;
        const walletAccountsCodes = walletAccounts.map(x => x.TradeCode);
		const payload = {
			TradeCodes: walletAccountsCodes,
			StartDate: new Date(0), // транзакции за весь период
			EndDate: moment().locale('en_GB').format('YYYYMMDD'),
			Page: page ?? 1,
			PageSize: 10,
			Type: OperationType.ALL,
		};
		dispatch(userAccounts.actions.getMoneyTransactionsHistory.request(payload));
	}

	useEffect(() => {
		loadTransactions();

        return () => {
			dispatch(userAccounts.actions.clearTransastionsHistory());
		};
	}, []);
    if (!currentWalletAccount) return null;

    return (
        <ContentCloud
            isOpen={isOpen}
            submitting={cashActivities.loading && cashActivities.items?.length === 0}
            onToggleOpen={onToggleOpen}
            id={'WalletTransactions' + currentWalletAccount.tradeCode}
            header={translate('tabs_transaction_history', 'Transactions History')}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell classes={{ root: classes.headCell }}>
                            <R
                                id="transaction_date"
                                defaultValue="Transaction date"
                            />
                        </TableCell>
                        <TableCell classes={{ root: classes.headCell }}>
                            <R
                                id="common_cryptocurrency"
                                defaultValue="Cryptocurrency"
                            />
                        </TableCell>
                        <TableCell classes={{ root: classes.headCell }}>
                            <R
                                id="shared_amount"
                                defaultValue="Price"
                            />
                        </TableCell>
                        <TableCell classes={{ root: classes.headCell }}>
                            <R
                                id="shared_status"
                                defaultValue="Status"
                                />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {walletsTransactionsHistory.length ?
                        walletsTransactionsHistory.map(x => 
                            <MyTableRow  key={x.Date}>
                                <MyTableCell>
                                    {moment.utc(x.Date).local().format('YYYY-MM-DD HH:mm:ss')}
                                </MyTableCell>
                                <MyTableCell>
                                    {x.Currency || x.CurrencyTo
                                        ? <LogoIcon currency={x.Currency ?? x.CurrencyTo} />
                                        : "-"
                                    }
                                </MyTableCell>
                                <MyTableCell>
                                    {moneyFormat(x.Amount ?? 0)}
                                </MyTableCell>
                                <MyTableCell>
                                    {x.DisplayStatus}
                                </MyTableCell>
                            </MyTableRow>)
                    : (
                        <MyTableRow>
                            <MyTableCell colSpan={6} classes={{ root: classes.noDataCell }}>
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    gridGap="10px"
                                >
                                    <NoDataIcon />
                                    <R id="shared_no_data_available" defaultValue="No data" />
                                </Box>
                            </MyTableCell>
                        </MyTableRow>
                        )}
                </TableBody>
            </Table>
            <ShowMore
                submitting={cashActivities.loading && cashActivities.items?.length > 0}
                onClick={() => loadTransactions(true)}
                showMoreButton={cashActivities.pagination.Page * cashActivities.pagination.PageSize <= cashActivities.items.length}
                htmlColor={theme.palette.text.primary}
            />
        </ContentCloud>
    )
}

export default J2txWalletTransactionsHistory;