import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
	reduxForm,
	Field,
	formValueSelector,
} from 'redux-form';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import {
	FormRadioGroup,
	FormCheckbox,
	useTranslate,
	FormSelect,
	Divider,
	Button,
	Radio,
	FreeMarginAmount,
} from '@components';
import { R, FormInput, FormInputNumber } from '@dev2t/react-ui-kit/src/components';
import { userAccounts, withdrawFunds } from '@redux';
import { template } from '@templates';

const useStyles = makeStyles(theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		paddingLeft: '50px',
	},
	divider: {
		marginTop: '30px',
		marginBottom: '20px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	preFieldLabel: {
		margin: '10px 24px 0px 24px',
		lineHeight: '21px',
		fontSize: '14px',
		color: theme.palette.text.secondary,
	},
	staticText: {
		margin: '10px 24px 10px 24px',
		lineHeight: '21px',
		fontSize: '14px',
		color: theme.palette.text.secondary,
	},
	fieldLeftAdditionalMargin: {
		marginLeft: '24px',
	},
	notesHeader: {
		fontSize: '18px',
	},
	notesText: {
		marginTop: '40px',
	},
	summCurrency: {
		fontSize: '2em',
		fontWeight: '700',
		lineHeight: '60px',
	},
	mb: {
		marginBottom: '24px',
	},
}));

function CYWithdrawalBankAmountForm({
	form, handleSubmit, invalid, submitting, requireIFSC
}) {
	const theme = useTheme;
	const classes = useStyles();
	const translate = useTranslate();

	const currentAccountDisplayFullName = useSelector(userAccounts.selectors.currentAccountDisplayFullName);

	const EMoneyOut = useSelector(state => formValueSelector(form)(state, 'EMoneyOut'));
	const NonOrderData = useSelector(state => formValueSelector(form)(state, 'NonOrderData'));

	return (
		<form
			onSubmit={handleSubmit}
			className={classes.root}
		>
			<div className={classes.accountContainer}>
				<div className={classes.row}>
					<div className={classes.logo}>
						<template.firmLogo />
					</div>
					<Field component={FormSelect} name="NonOrderData.TradeCode" label={translate('common_account', 'Account')} disabled>
						<MenuItem value={NonOrderData.TradeCode}>
							<div className={classes.inputContainer}>
								<div className={classes.account}>{currentAccountDisplayFullName}</div>
							</div>
						</MenuItem>
					</Field>
				</div>
			</div>
			<div className={classes.divider}>
				<Divider />
			</div>
			<div className={classes.inputItem}>
				<p className={classes.preFieldLabel}>
					<R id="moneyout_emoney_currency_title" defaultValue="Currency of your wallet/account/card" />
				</p>
				<div className={classes.fieldLeftAdditionalMargin}>
					<Field
						name="EMoneyOut.AccountCurrency"
						orientation="horizontal"
						component={FormRadioGroup}
					>
						<Radio value={EMoneyOut.AccountCurrency} label={EMoneyOut.AccountCurrency} />
					</Field>
				</div>
			</div>
			<div className={classes.inputItem}>
				<Field
					component={FormInput}
					name="EMoneyOut.TargetAccount"
					label={translate('orders_targetaccount_malaysianbank', 'Beneficiary Account')}
				/>
			</div>
			<div className={classes.inputItem}>
				<Field
					component={FormInput}
					name="EMoneyOut.BeneficiaryName"
					label={translate('shared_beneficiary_name_title', 'Beneficiary name')}
				/>
			</div>
			<div className={classes.inputItem}>
				<Field
					component={FormInput}
					name="EMoneyOut.BankName"
					label={translate('shared_bank_name_title', 'Bank name')}
				/>
			</div>
			{requireIFSC &&
				<div className={classes.inputItem}>
					<Field
						component={FormInput}
						name="EMoneyOut.IFSC"
						label={translate('IFSC_code', 'IFSC Code')}				
					/>
				</div>
			}
			<div className={classes.inputItem}>
				<Field
					component={FormInput}
					name="EMoneyOut.Comment"
					label={translate('shared_comment', 'Comment')}
					multiline
				/>
			</div>
			<div className={classNames(classes.inputItem, classes.mb)}>
				<Box display="flex" alignItems="center">
					<Field
						component={FormInputNumber}
						name="EMoneyOut.Summ"
						label={translate('moneyout_amount', 'Transfer amount')}
						allowIconButtons={false}
						prefix=""
					/>
					<div className={classes.summCurrency}>
						{EMoneyOut.Currency}
					</div>
				</Box>
				<FreeMarginAmount currency={EMoneyOut.Currency}/>
			</div>

			<div className={classes.inputItem}>
				<Field
					component={FormCheckbox}
					name="NonOrderData.ConfirmAccountOwner"
					label={translate(
						'common_confirm_account_owner',
						'I confirm that the monetary funds shall be transferred to my personal bank account.',
					)}
				/>
			</div>
			<div className={classNames(classes.inputItem, classes.mb)}>
				<Field
					component={FormCheckbox}
					name="NonOrderData.ConfirmDataCorrect"
					label={translate(
						'common_moneyout_confirm_data_correct',
						'I confirm that all the details specified in the order are true.',
					)}
				/>
			</div>
			<Button
				fullWidth
				type="submit"
				variant="contained"
				color="primary"
				disabled={invalid || submitting}
			>
				{submitting
					? (
						<CircularProgress
							style={{ color: theme.palette.main.contrastText }}
							size={18}
						/>
					)
					: <R id="common_btn_continue" defaultValue="Continue" />}
			</Button>
			<div className={classes.notesText}>
				<div className={classes.notesHeader}>
					<R id="important_notes_header" defaultValue="Important Notes" />
				</div>
				<R
					id="orders_important_note_malaysianbank"
					// eslint-disable-next-line max-len
					defaultValue="<ul><li>Withdrawal requests are processed within an hour.</li><li>Pay attention to the fact that on US and European exchanges there is a concept of “settlement date” which means the date on which the trade settles and ultimate transfer of cash and securities occurs. Therefore withdrawal of available balance can be only made after the settlement date comes, i.e. on 3rd (Xetra) or 4th (USA) working day.</li></ul>"
					enableHtml
				/>
			</div>
		</form>
	);
}

CYWithdrawalBankAmountForm.propTypes = {
	form: PropTypes.string.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	// commission: PropTypes.object.isRequired,
	
	requireIFSC: PropTypes.bool,
	invalid: PropTypes.bool.isRequired,
	submitting: PropTypes.bool.isRequired,
};

function withValidation(Component) {
	function ComponentWithValidation(props) {
		const translate = useTranslate();
		const freeMargin = useSelector(withdrawFunds.selectors.freeMarginAmount);
		const amountFormValidate = (formvalues, props) => {
			const values = formvalues;
			const errors = {};
			if (!values) {
				return errors;
			}

			if (values.EMoneyOut) {
				errors.EMoneyOut = {};

				if (!values.EMoneyOut.Summ) {
					errors.EMoneyOut.Summ = 'required';
				}
				if (!values.EMoneyOut.TargetAccount) {
					errors.EMoneyOut.TargetAccount = 'required';
				}
				if (!values.EMoneyOut.BankName) {
					errors.EMoneyOut.BankName = 'required';
				}
				if (!values.EMoneyOut.BeneficiaryName) {
					errors.EMoneyOut.BeneficiaryName = 'required';
				}
				if (!values.EMoneyOut.DestinationTag) {
					errors.EMoneyOut.DestinationTag = 'required';
				}
				if (!values.EMoneyOut.IFSC && props.requireIFSC) {
					errors.EMoneyOut.IFSC = 'required';
				}
				if (values.EMoneyOut.Summ > parseFloat(freeMargin.value)) {
					errors.EMoneyOut.Summ = translate('max_withdraw_amount_error', 'Available amount for withdrawal is less than requested');
				}
			}
			if (values.NonOrderData) {
				errors.NonOrderData = {};
				if (!values.NonOrderData.ConfirmAccountOwner) {
					errors.NonOrderData.ConfirmAccountOwner = 'required';
				}
				if (!values.NonOrderData.ConfirmDataCorrect) {
					errors.NonOrderData.ConfirmDataCorrect = 'required';
				}
			}
			return errors;
		};
		return <Component {...props} validate={amountFormValidate} />;
	}
	return ComponentWithValidation;
}

export default compose(
	withValidation,
	reduxForm({
		onSubmitSuccess: (result, dispatch, props) => {
			if (props.onSuccess && (typeof props.onSuccess === 'function')) {
				props.onSuccess();
			}
		},
		onSubmitFail: (errors, dispatch, submitError, props) => {
			// eslint-disable-next-line no-underscore-dangle
			if (errors._error && props.onFail) {
				props.onFail();
			}
		},
	}),
)(CYWithdrawalBankAmountForm);
