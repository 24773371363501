/* eslint-disable arrow-body-style */
import React from 'react';
import MUIRadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = theme => ({
	horizontal: {
		flexDirection: 'row',
	},
	vertical: {
		flexDirection: 'column',
	},
	withLabelRoot: {
		marginTop: '16px',
		padding: '12px 24px 5px',
		backgroundColor: 'rgba(255,255,255,0.05)',
		borderRadius: '8px',
		color: theme.palette.text.primary,
		fontSize: theme.typography.pxToRem(18),
	},
	label: {
		top: '10px',
		left: '24px',
		...theme.customstyles.title,
		transform: 'translate(0, 1.5px) scale(0.75)',
		transformOrigin: 'top left',
	},
	errorMessageRoot: {
		'&$error': {
			padding: '0px 24px',
			...theme.customstyles.body,
			color: theme.palette.error.main,
		  },
	},
	error: { }
});

const RadioGroup = ({
	error,
	helperText,
	fullWidth,
	children,
	classes,
	showLabel,
	placeholder,
	orientation,
	validationMessageMode,
	...props
}) => {
	const cssRootClasses = classNames({
		[classes.horizontal]: orientation === 'horizontal',
		[classes.vertical]: orientation === 'vertical',
		[classes.withLabelRoot]: showLabel,
	});

	const items = React.Children.map(children, (child) => {
		if (!React.isValidElement(child)) {
			return null;
		}
		return React.cloneElement(child, {
			error,
			helperText,
			checked: child.props.value === props.value,
		});
	});

	return (
		<FormControl error={error} fullWidth={fullWidth}>
			{showLabel &&
				<InputLabel classes={{ formControl: classes.label }} htmlFor={props.name}>
					{placeholder}
				</InputLabel>
			}
			{error && validationMessageMode === 'withErrorMessage' &&
				<FormHelperText classes={{
					root: classes.errorMessageRoot,
					error: classes.error,
				  }}>
					{helperText}
				</FormHelperText>
			}
			<MUIRadioGroup classes={{ root: cssRootClasses }}{...props}>
				{items}
			</MUIRadioGroup>
		</FormControl>
	);
};

RadioGroup.propTypes = {
	orientation: PropTypes.oneOf(['horizontal', 'vertical']),
	validationMessageMode: PropTypes.oneOf(['none', 'withErrorMessage']),
	showLabel: PropTypes.bool,
	placeholder: PropTypes.string,
};

RadioGroup.defaultProps = {
	orientation: 'vertical',
	showLabel: false,
	placeholder: '',
	validationMessageMode: 'none',
};

export default withStyles(styles)(RadioGroup);
