import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import compose from 'recompose/compose';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { constants, env } from '@config';
import { cabinetLog, scrollIntoView, translate } from '@global';
import { auth } from '@redux';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { Button, Select} from '@components';
import { R, FormInput } from '@dev2t/react-ui-kit/src/components';

const styles = {
	root: {
		display: 'block',
		position: 'relative',
	},
	progress: {
		display: 'flex',
		justifyContent: 'center',
	},
	confirmLink: {
		marginTop: '16px',
		paddingLeft: '24px',
	},
	link: {
		textTransform: 'uppercase',
	},
	button: {
		paddingTop: '24px',
	},
};

const getConfirmTypes = () => [
	{value: 'email', title: translate('confirm_type_email', 'Email')},
	{value: 'sms', title: translate('confirm_type_sms', 'SMS')},
];



const mapDispatchToProps = dispatch => ({
	actions: {
		sendConfirmationCode: (phone, reason) => dispatch(auth.actions.sendConfirmationCodeRequest(phone, reason || 'Confirmation')),
		sendEmailConfirmationCode: (email, reason)  => dispatch(auth.actions.sendEmailConfirmationCodeRequest(email, reason || 'Confirmation')),
	},
});

class RequireConfirmationCodeItem extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			hasConfirmationCode: false,
			showPassword: false,
			password: '',
			currentCount: 0,
			intervalId: null,
			confirmType: 'sms',
		};
	}

	componentWillUnmount() {
		// use intervalId from the state to clear the interval
		clearInterval(this.state.intervalId);
	}


	componentDidUpdate() {
		const {
			hasConfirmationCode,
			currentCount,
		} = this.state;

		if (hasConfirmationCode && currentCount == 120) {
			scrollIntoView('js-confirm-code-place');
		}
	}

	onRequestVerificationCode = () => {
		const {
			actions,
			reason,
			onSendCode,
		} = this.props;
		const {
			confirmType,
		} = this.state;
		
		const byemail = confirmType === 'email';

		if (onSendCode && (typeof onSendCode === 'function')) {
			onSendCode(null, reason, byemail);
		} else {
			if (byemail)
				actions.sendEmailConfirmationCode(null, reason);
			else
				actions.sendConfirmationCode(null, reason);
		}

		const intervalId = setInterval(this.timer, 1000);
		this.setState({
			hasConfirmationCode: true,
			currentCount: 120,
			intervalId,
		});
	}

	onChacngeTypeConfirm = (value) => {
		this.setState({confirmType: value})
	}

	handleClickShowSmsCode = () => this.setState({ showPassword: !this.state.showPassword });

	timer = () => {
		if (this.state.currentCount === 0) {
			clearInterval(this.state.intervalId);
		} else {
			this.setState({ currentCount: this.state.currentCount - 1 });
		}
	}

	render() {
		const {
			classes,
			customClasses,
			disableConfirmation,
			name,
			children,
			buttonTitle,
			disabled,
			onSendCode, // for not includes in rest!!!
			...rest
		} = this.props;

		const {
			hasConfirmationCode,
			currentCount,
			confirmType,
		} = this.state;

		if (disableConfirmation) {
			return (
				<div className={(customClasses && customClasses.button) || classes.button}>
					{children}
				</div>)
		}

		const showchangetype = [constants.LIME_LME].some(x => x === env.firm) 
							&& !(onSendCode && (typeof onSendCode === 'function'));

		return (
			<React.Fragment>
				<div id="js-confirm-code-place" className={(customClasses && customClasses.inputItem) || classes.inputItem}>
					{hasConfirmationCode && currentCount > 0 &&
						<Field
							component={FormInput}
							name={name}
							label={translate("shared_verification_code", "Verification Code")}
							type="text"
							value={this.state.password}
							props={{
								style: {
									textSecurity: `${!this.state.showPassword ? "disc" : ""} `, 
									WebkitTextSecurity: `${!this.state.showPassword ? "disc" : ""}`,
									MozTextSecurity: `${!this.state.showPassword ? "disc" : ""}`
								}
							}}
							{...rest}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="Toggle password visibility"
										onClick={this.handleClickShowSmsCode}
									>
										{this.state.showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
						/>
					}
					<div className={(customClasses && customClasses.confirmLink) || classes.confirmLink}>
						{hasConfirmationCode && showchangetype &&
							<p>
							   <R id="common_not_received_confirm_code"	
							  	  defaultValue="In case you have not received the code, select the sending method and try again."
							   />
							</p>
						}
						{hasConfirmationCode && !showchangetype &&
							<p>
							   <R id="common_not_received_confirm_code_mochange"	
							  	  defaultValue="In case you have not received the code, try again later or contact support."
							   />
							</p>
						}
						{currentCount > 0 &&
							<p>
								<R id="common_new_code_in_seconds" translateParameters={{ seconds: currentCount }} defaultValue="You can request new code in {seconds} seconds" />
							</p>
						}
					
						{currentCount === 0  
						&& showchangetype
						&& hasConfirmationCode && (
							<div>
								<p>
									<R id="type_send_confirm_code_title" defaultValue="Code submission method" />
								</p>
								<Select
									value={confirmType}
									onChange={(event) => this.onChacngeTypeConfirm(event.target.value)}
								>
									{getConfirmTypes().map(x => (
										<MenuItem value={x.value} key={x.value}>
											{x.title}
										</MenuItem>
									))}
								</Select>
							</div>
						)}
					</div>
				</div>
				<div className={(customClasses && customClasses.button) || classes.button}>
					{hasConfirmationCode && currentCount > 0 &&
						<React.Fragment>
							{children}
						</React.Fragment>
					}
					{(currentCount === 0 || !hasConfirmationCode) &&
						<Button
							fullWidth
							variant="contained"
							color="primary"
							onClick={this.onRequestVerificationCode}
							disabled={disabled}
						>
							{ buttonTitle } 
						</Button>
					}
				</div>
			</React.Fragment>
		);
	}
}

RequireConfirmationCodeItem.propTypes = {
	classes: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	/**
	 * При указании true не будет запрашиваться подтверждение, т.е. просто будет выведен контент компонента
	 */
	disableConfirmation: PropTypes.bool,
};

RequireConfirmationCodeItem.defaultProps = {
	disableConfirmation: false,
};

export default compose(
	withStyles(styles),
	connect(null, mapDispatchToProps),
)(RequireConfirmationCodeItem);
