import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { ModalPopups } from '@enums';
import { userAccounts, depositFunds } from '@redux';
import { ErrorMessage, ProgressContainer } from '@components';
import { Modal } from '@dev2t/react-ui-kit/src/components';
import { env } from '@config';
import { connect } from 'react-redux';
import { DepositFundsBrokerForm } from './components';

const styles = {};

const checkEnableWallet = (userInfo) => {
	const userGuid = userInfo.Guid && userInfo.Guid.toUpperCase();
	return userGuid && env.WEBCAB_5004_walletEnableUsers.includes(userGuid);
};

const mapStateToProps = (state) => {
	/* 
	 const depositBrokerModal = depositFunds.selectors.depositBrokerModal(state);
	if (depositBrokerModal.bankAccount && depositBrokerModal.tradeAccount) {
		return {
			depositBrokerModal,
			canShow: true,
			loading: false,
		};
	} */
	// default if not set
	const loading = userAccounts.selectors.loading(state) || userAccounts.selectors.accountInfo(state).loading;
	const tradeAccount = userAccounts.selectors.currentTradeAccount(state);
	const linkedAccounts = userAccounts.selectors.linkedAccounts(state);
	const accountStatus = userAccounts.selectors.currentAccountStatus(state);
	const bankAccount = loading ? null : {
		Type: 'BROKER',
		Number: '458408',
		BankName: 'J2T Account',
	};
	return {
		depositBrokerModal: {
			bankAccount,
			tradeAccount,
		},
		canShow: !!(accountStatus && accountStatus.CanFund && checkEnableWallet(userAccounts.selectors.userInfo(state))),
		loading,
	};
};

class DepositFundsBrokerModal extends React.Component {
	render() {
		const {
			canShow,
			loading,
			onClose,
			depositBrokerModal,
		} = this.props;

		return (
			<Modal
				title="Deposit"
				onClose={onClose}
				className={`QA-${ModalPopups.DEPOSIT_FUNDS_BROKER}`}
			>
				<ProgressContainer submitting={loading}>
					<div>
						{canShow && !depositBrokerModal.bankAccount &&
							<ErrorMessage onClick={onClose} text={`Account ${depositBrokerModal.tradeAccount.tradeCode} is not linked.`} />
						}
						{!canShow &&
							<ErrorMessage onClick={onClose} text={`Unavailable for account ${depositBrokerModal.tradeAccount.tradeCode}.`} />
						}
						{depositBrokerModal.bankAccount && canShow &&
							<DepositFundsBrokerForm
								bankAccount={depositBrokerModal.bankAccount}
								tradeAccount={depositBrokerModal.tradeAccount}
							/>
						}
					</div>
				</ProgressContainer>
			</Modal>
		);
	}
}

DepositFundsBrokerModal.propTypes = {
	canShow: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	depositBrokerModal: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	connect(mapStateToProps, null),
)(DepositFundsBrokerModal);
