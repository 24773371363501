import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@components';
import { Input } from '@dev2t/react-ui-kit/src';
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
	root: {
		marginLeft: '24px',
		marginRight: '24px',
	},
});
const StaticField = ({ label, value }) => (
	<Grid item xs={12} md={6}>
		<Input label={label} showLabel value={(value || '-')} disabled fullWidth />
	</Grid>
);
const StyledDivider = withStyles(styles)(Divider);
class BankAccountItemContent extends React.Component {
	render() {
		const {
			classes,
			linkedaccount,
		} = this.props;

		// It,s for Lime International Only
		if (linkedaccount.Type === 'BankTransfer') {
			return (
				<Grid container>
					<Grid item container>
						<StaticField label="Account number" value={linkedaccount.Number} />
						
						{linkedaccount.Template.RecipientBankSwift ?
							<React.Fragment>
								<StaticField label="Swift code" value={linkedaccount.Template.RecipientBankSwift} />
								<StyledDivider />
								<StaticField label="Cor bank" value={linkedaccount.Template.BankCorrespondent} />
								<StaticField label="Cor bank account" value={linkedaccount.Template.RecipientBankCorAccount} />
								<StaticField label="Cor bank swift" value={linkedaccount.Template.BankCorrespondentSwift} />
							</React.Fragment>
							:
							<React.Fragment>
								<StaticField label="Bank bik" value={linkedaccount.Template.RecipientBankBIK} />
								<StaticField label="Cor bank account" value={linkedaccount.Template.RecipientBankCorAccount} />
							</React.Fragment>
						}
					</Grid>
					{linkedaccount.Template.UseIntermediaryBank &&
						<Grid item container>
							<StyledDivider />
							<StaticField label="Intermediary Bank name" value={linkedaccount.Template.IntermediaryBank} />
							<StaticField label="Intermediary Bank swift" value={linkedaccount.Template.IntermediaryBankSwift} />
						</Grid>
					}
				</Grid>
			);
		}

		return (
			<Grid container>
				<Grid item container>
					<StaticField label="Account number" value={linkedaccount.Number} />
					<StaticField label="Routing number" value={linkedaccount.BankRoutingNumber} />
					{linkedaccount.Type === 'WIRE' &&
						<StaticField label="Swift code" value={linkedaccount.Template.ReceivingBankSwiftCode} />
					}
				</Grid>
				{linkedaccount.Type === 'WIRE' && linkedaccount.Template.IntermediaryBankName &&
					<Grid item container>
						<StyledDivider />
						<StaticField label="Intermediary Bank name" value={linkedaccount.Template.IntermediaryBankName} />
						<StaticField label="Intermediary Bank Account number" value={linkedaccount.Template.IntermediaryBankAccountNumber} />
						<StaticField label="Intermediary Bank Routing number" value={linkedaccount.Template.IntermediaryBankRoutingNumber} />
						<StaticField label="Intermediary Bank Swift code" value={linkedaccount.Template.IntermediaryBankSwiftCode} />
					</Grid>
				}
			</Grid>
		);
	}
}

BankAccountItemContent.propTypes = {
	linkedaccount: PropTypes.object.isRequired,
};

export default withStyles(styles)(BankAccountItemContent);
