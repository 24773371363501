import { useSelector } from 'react-redux';
import { app } from '@redux';
import { toMoneyFormat } from '@global';

export const useMoneyFormat = (defaultOptions) => {
	const language = useSelector(app.selectors.language);
	const moneyFormat = (amount, currencyCode, options) => {
		const effectiveOptions = {
			...(defaultOptions || {}),
			...(options || {}),
		};
		let { nullReplace, precision, disableCurrencyCode } = effectiveOptions;
		nullReplace = nullReplace || '-';
		precision = (!precision && precision !== 0) ? 2 : precision;

		if (amount || amount === 0) {
			const sMoney = toMoneyFormat(amount, language, currencyCode, precision);
			if (currencyCode && !disableCurrencyCode) {
				const currencySymbol = currencyCode.length === 3 ? `${sMoney} ${currencyCode}` : `${currencyCode}${sMoney}`;
				return currencySymbol;
			}
			return sMoney;
		}
		return nullReplace;
	};
	return moneyFormat;
};
