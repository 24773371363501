import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '@dev2t/react-ui-kit/src';
import CustomEditableItem from './CustomEditableItem';
// eslint-disable-next-line no-unused-vars

function valueOrDefault(val, defaultVal) {
	if (val) return val;
	return defaultVal;
}
const InputEditableItem = ({
	title,
	value,
	valueIfEmpty,
	...otherProps
}) => {
	const valueControl = (<Input label={title} showLabel value={valueOrDefault(value, valueIfEmpty)} disabled />);
	return (
		<CustomEditableItem
			valueControl={valueControl}
			{...otherProps}
		/>
	);
};

InputEditableItem.propTypes = {
	title: PropTypes.string.isRequired,
	value: PropTypes.string,
	valueIfEmpty: PropTypes.string,
};
InputEditableItem.defaultProps = {
	value: undefined,
	valueIfEmpty: '',
};
export default InputEditableItem;
