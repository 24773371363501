import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import SafeComponent from 'react-safe-component';
import { toUsaFormat } from '@global';
import moment from 'moment';
import classNames from 'classnames';
import { constants, env } from '@config';
import { OperationType } from '@enums';
import { R } from '@dev2t/react-ui-kit/src/components';

const styles = theme => ({
	root: {
		display: 'flex',
	},
	leftEmptyColumn: {
		width: '40px',
	},
	leftColumn: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
	},
	logoColumn: {
		display: 'flex'
	},
	logoIcon: {
	    width: '40px',
    	height: '40px',
    	alignSelf: 'center',
	},
	middleColumn: {
		marginLeft: '5px',
		flexGrow: '2',
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
	},
	rightColumn: {
		display: 'flex',
		alignContent: 'center',
		justifyContent: 'flex-start',
		textAlign: 'right',
	},
	title: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
	},
	titleSymbol: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
		fontSize: '18px',
	},
	date: {
		...theme.customstyles.body,
		color: theme.palette.text.secondary,
	},
	amount: {
		...theme.customstyles.body,
		color: theme.palette.text.primary,
		fontSize: '18px',
	},
	status: {
		...theme.customstyles.body,
	},
	amountData: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		textAlign: 'right',
	},
	yellow: {
		color: theme.palette.warning.main,
	},
	red: {
		color: theme.palette.error.main,
	},
	green: {
		color: theme.palette.success.main,
	},
	black: {
		color: theme.palette.text.primary,
	},
	grey: {
		color: theme.palette.text.secondary,
	},
});

class CYFundItem extends React.Component {
	render() {
		const {
			item,
			classes,
		} = this.props;

		const colorCssClass = classNames({
			[classes.red]: item.Color === 'red',
			[classes.green]: item.Color === 'green',
			[classes.black]: item.Color === 'black',
			[classes.black]: item.Color === 'gray',
		});


		let fundTitle;
		if ([constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm)) {
			fundTitle = OperationType.getTranslateId(item.Type);
			if (item.Type === 'Output' && item.Amount > 0) {
				item.Amount = -item.Amount;
			}
		} else {
			fundTitle = item.Type;
			switch (item.Type) {
				case 'AssignmentOrder':
					fundTitle = 'Assignment Order';
					break;
				case 'ClientPasswordChange':
					fundTitle = 'Client\'s Password Change';
					break;
				case 'Output':
					if (item.Amount > 0) {
						item.Amount = -item.Amount;
					}
					break;
	
				default:
					fundTitle = item.Type;
			}
		}

		let amount = '';
		if (item.Amount != null) {
			if (item.Amount !== 0 && item.RawType !== 'AccountsTransfer') {
				amount = item.Amount > 0 ? '+' : '-';
			}
			amount += toUsaFormat(Math.abs(item.Amount));
		}

		const logoFileName = item.Bank ? `${item.Bank}.png` : null;

		return (
			<div className={classes.root}>
				{/* <div className={classes.leftColumn}>
					{!item.IsUserTransaction && 
						(
							item.LogoIconUrl ?
							<div className={classes.logoColumn}>
								<img className={classes.logoIcon} src={item.LogoIconUrl}></img>
							</div>
							:
							<div className={classes.leftEmptyColumn} />
						) 
					}
					{item.IsUserTransaction &&
						<BankLogo bankAccount={{ LogoFileName: logoFileName, BankName: item.Bank }} />
					}
				</div> */}
				<div className={classes.middleColumn}>
					{item.Symbol &&
						<div className={classes.titleSymbol}>
							{item.Symbol}
						</div>
					}
					<div className={classes.title}>
						<R id={fundTitle}/>
					</div>
					<div className={classes.date}>
						{/* american time zone (no utc) */}
						{moment.utc(item.Date).local().format('D MMM YYYY')}
					</div>
				</div>
				<div className={classes.rightColumn}>
					<div className={classes.amountData}>
						{amount &&
							<div className={classes.amount}>
								<div className={colorCssClass}>
									{amount + ' ' + item.Currency}
								</div>
							</div>
						}
						{item.DisplayStatus &&
							<div className={classes.status}>
								<div className={colorCssClass}>
									{[constants.LIME_LME, constants.FIRM_J2TX].some(x => x === env.firm) ? <R id={`orders_${item.Status.toLowerCase()}_state_text`}/> : item.DisplayStatus}
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		);
	}
}

CYFundItem.propTypes = {
	item: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	SafeComponent,
)(CYFundItem);