import React from 'react';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
	reduxForm,
	formValueSelector,
} from 'redux-form';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { Button } from '@components';
import { R } from '@dev2t/react-ui-kit/src/components';
import { userAccounts, app } from '@redux';


const styles = theme => ({
	root: {
		position: 'relative',
	},
	row: {
		display: 'flex',
	},
	inputItem: {
		paddingLeft: '50px',
	},
	logo: {
		padding: '16px 16px 4px 16px',
		position: 'relative',
		width: '80px',
		height: '44px',
	},
	inputContainer: {
		display: 'flex',
		width: '100%',
		...theme.customstyles.title,
	},
	account: {
		width: '100%',
		textAlign: 'left',
	},
	button: {
		...theme.mixins.stickyButton,
	},
	methodsContainer: {
		marginBottom: '24px',
	},
	gridItem: {
		minWIdth: 'fit-content',
	},
	methodItem: {
		height: '100%',
		textAlign: 'center',
		padding: '20px',
		border: '2px solid #deeaef',
		borderRadius: '8px',
		'&:hover': {
			boxShadow: '0 5px 10px 0 rgba(61, 65, 71, 0.16)',
			borderWidth: '2px',
			transform: 'translate(0, 1.5px) scale(1.01)',
		},
		
	},
	methodItemSelected: {
		borderColor: theme.palette.text.secondary,
	},
	methodImg: {
		maxWidth: '120px',
		height: '80px',
		[theme.breakpoints.up('sm')]: {
			height: '120px',
		},
	},
	buttonCenter: {
		margin: '0 auto',
		width: '30%',
		display: 'block',
	},

});

const mapStateToProps = (state, ownProps) => {
	const currentTradeAccount = userAccounts.selectors.currentTradeAccount(state);

	const language = app.selectors.language(state);

	const OpenAccountOut = formValueSelector(ownProps.form)(state, 'OpenAccountOut');
	return {
		OpenAccountOut,
		currentTradeAccount,
		language,
	};
};
const getMethods = () => [
	{
		SelectedType: 'ForexStandart',
		Title: 'Forex & CFDs Standart',
		Data: [
			{ key: 'open_account_type_minimum_amount', text: 'Minimum amount', value: '$100' },
			{ key: 'open_account_type_marging_trading', text: 'Marging trading', value: 'Yes' },
			{ key: 'open_account_type_spread', text: 'Spread', value: 'from 0,5' },
			{ key: 'open_account_type_minimum_lot', text: 'Minimum lot', value: '0,01' },
			{ key: 'open_account_type_brokerage_commission', text: 'Brokerage commission', value: '0' },
			{ key: 'open_account_type_negative_balance_protection', text: 'Negative balance protection', value: 'Yes' },
			{ key: 'open_account_type_swap', text: 'Swap', value: 'Yes' },
			{ key: 'open_account_type_leverage_up_to', text: 'Leverage up to', value: '1:2000' },
			{ key: 'open_account_type_mobile_trading', text: 'Mobile Trading', value: 'Yes' },
		],
		LanguageFilter: null,
		MaxLeverage: 2000,
	},
	{
		SelectedType: 'ForexEcn',
		Title: 'Forex ECN',
		Data: [
			{ key: 'open_account_type_minimum_amount', text: 'Minimum amount', value: '$100' },
			{ key: 'open_account_type_marging_trading', text: 'Marging trading', value: 'Yes' },
			{ key: 'open_account_type_spread', text: 'Spread', value: 'from 0' },
			{ key: 'open_account_type_minimum_lot', text: 'Minimum lot', value: '0,01' },
			{ key: 'open_account_type_brokerage_commission', text: 'Brokerage commission', value: '$3 per lot' },
			{ key: 'open_account_type_negative_balance_protection', text: 'Negative balance protection', value: 'Yes' },
			{ key: 'open_account_type_swap', text: 'Swap', value: 'Yes' },
			{ key: 'open_account_type_leverage_up_to', text: 'Leverage up to', value: '1:2000' },
			{ key: 'open_account_type_mobile_trading', text: 'Mobile Trading', value: 'Yes' },
		],
		LanguageFilter: null,
		MaxLeverage: 2000,
	},
	{
		SelectedType: 'ForexMUSLIM',
		Title: 'Islamic account',
		Data: [
			{ key: 'open_account_type_minimum_amount', text: 'Minimum amount', value: '$100' },
			{ key: 'open_account_type_marging_trading', text: 'Marging trading', value: 'Yes' },
			{ key: 'open_account_type_spread', text: 'Spread', value: 'from 0,5' },
			{ key: 'open_account_type_minimum_lot', text: 'Minimum lot', value: '0,01' },
			{ key: 'open_account_type_brokerage_commission', text: 'Brokerage commission', value: '0' },
			{ key: 'open_account_type_negative_balance_protection', text: 'Negative balance protection', value: 'Yes' },
			{ key: 'open_account_type_swap', text: 'Swap', value: 'No' },
			{ key: 'open_account_type_leverage_up_to', text: 'Leverage up to', value: '1:500' },
			{ key: 'open_account_type_mobile_trading', text: 'Mobile Trading', value: 'Yes' },
		],
		LanguageFilter: ['ms', 'id'],
		MaxLeverage: 500,
	},
];

class CYOpenAccountChooseType extends React.Component {
	static validate(formvalues, props) {
		const values = formvalues;
		const errors = {};

		if (!values) {
			return errors;
		}

		if (values.OpenAccountOut) {
			errors.OpenAccountOut = {};

			if (!values.OpenAccountOut.SelectedType) {
				errors.OpenAccountOut.SelectedType = 'required';
			}
		}

		return errors;
	}
	selectMethod = (m) => {
		const { OpenAccountOut, change } = this.props;
		change('OpenAccountOut', {
			...OpenAccountOut,
			SelectedType: m.SelectedType,
			MaxLeverage: m.MaxLeverage,
		});
	};

	render() {
		const {
			classes,
			fromUserAccount,
			handleSubmit,
			submitSucceeded,
			currentTradeAccount,
			theme,
			values,
			OpenAccountOut,
			language,
			...props
		} = this.props;

		return (
			<form
				onSubmit={handleSubmit}
				className={classes.root}
			>
				<div className={classes.methodsContainer}>
					<Grid
						container
						spacing={3}
						justifyContent="center"

					>
						{getMethods().filter(f => !f.LanguageFilter || f.LanguageFilter.includes(language)).map(m => (
							<Grid className={classes.gridItem} item sm={['ms', 'id'].includes(language) ? 4 : "auto"} key={m.SelectedType}>
								<div
									className={classNames({
										[classes.methodItem]: true,
										[classes.methodItemSelected]: OpenAccountOut.SelectedType === m.SelectedType,
									})}
									onClick={() => this.selectMethod(m)}
								>
									<div><h2>{m.Title}</h2></div>
									<table>
										<tbody>
											{ m.Data.map((item, index) => (
												<tr className="accounts__row" key={`account-text-${index}`}>
													<td className="accounts__text" align="left">{ <R id={item.key} defaultValue={item.text} /> }</td>
													<td className="accounts__value" align="right">{item.value}</td>
												</tr>

											))
											}
										</tbody>
									</table>
								</div>
							</Grid>
						))}
					</Grid>
				</div>
				<div className={classes.buttonCenter}>
					<Button
						fullWidth
						type="submit"
						variant="contained"
						color="primary"
						disabled={props.invalid || props.submitting || !OpenAccountOut.SelectedType}
					>
						{props.submitting ?
							<CircularProgress
								style={{ color: theme.palette.common.white }}
								size={18}
							/>
							: <R id="common_btn_continue" defaultValue="Continue" />
						}
					</Button>
				</div>
			</form>
		);
	}
}

CYOpenAccountChooseType.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	currentTradeAccount: PropTypes.object.isRequired,
};

export default compose(
	withStyles(styles),
	withTheme,
	connect(mapStateToProps, null),
	reduxForm({
		validate: CYOpenAccountChooseType.validate,
	}),
)(CYOpenAccountChooseType);
