import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { R } from '@dev2t/react-ui-kit/src';
import { withdrawFunds, userAccounts } from '@redux';
import { makeStyles } from '@material-ui/core/styles';
import { useMoneyFormat } from '@hooks';
import { constants, env } from '@config';
import { LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';

export const LoadFreeMarginType =  {
    WITHDRAW: "WITHDRAW",
    TRANSFER: "TRANSFER"
}

const useStyles = makeStyles(theme => ({
	cashToWithdrawInfoText: {
		fontSize: '14px',
		marginTop: 0,
		paddingTop: 0,
		color: theme.palette.text.secondary,
		paddingLeft: '24px',
		whiteSpace: 'pre-wrap',
        width: '100%',
	},
    loader: {
		color: theme.palette.primary.main,
	},
}));

const useActions = () => {
	const dispatch = useDispatch();
	return ({
		getMaxWithdrawal: (query) => dispatch(withdrawFunds.actions.getMaxWithdrawAmount.request(query)),
        getMaxTransfer: (query) => dispatch(withdrawFunds.actions.getMaxTransferAmount.request(query)),
	});
};

const hideFiatCurrencyCodeForFirms = [constants.LIME_LME, constants.FIRM_J2TX].every(x => x !== env.firm);

/**
 * @param {string} currency - Отображаемая валюта, также передается в апи метод получения free margin
 * @param {number} availableAmount - Отображаемая сумма, если передать, то запрос на получение free margin не будет выполняться
 * @param {string} withdrawLabelText - Если не указан, то используется дикворд common_amount_available_to_withdraw
 * @param {string} loadFreeMarginType - Используется для определения апи метода получения free margin
 * @param {number} finalPrecision - Количество знаков после запятой значения free margin
 */
export const FreeMarginAmount = ({ 
    currency, 
    availableAmount, 
    withdrawLabelText,
    loadFreeMarginType,
    finalPrecision,
}) => {
    const classes = useStyles();
    const loadType = loadFreeMarginType ?? LoadFreeMarginType.WITHDRAW;

    const freeMarginAmount = useSelector(withdrawFunds.selectors.freeMarginAmount);
    const currentAccount = useSelector(userAccounts.selectors.selectedAccount);
    const actions = useActions();
    const format = useMoneyFormat({ precision: finalPrecision });
    
    useEffect(() => {
        if(availableAmount !== undefined) {
            return;
        }

        switch (loadType) {
            case LoadFreeMarginType.WITHDRAW:
                actions.getMaxWithdrawal({account: currentAccount.TradeCode, currency: currency});
                break;
            case LoadFreeMarginType.TRANSFER:
                actions.getMaxTransfer({account: currentAccount.TradeCode, currency: currency});
                break;
            default:
                break;
        }
    }, []);
    
    if(freeMarginAmount.submitting) {
        return (
                <div className={classes.cashToWithdrawInfoText}>
                    <LinearProgress classes={{ root: classes.loader }}/>
                </div>);
    }
    
    // availableAmount === undefined, поддержка старой логики с валидацией.
    // По FreeMargin, проверяется передана ли сумма
    //
    // freeMarginAmount.value === null, значит, что используется новая логика. 
    // С бэкенда всегда приходит null, если нет free margin
    if(availableAmount === undefined && freeMarginAmount.value === null){
        return <></>;
    }

    const sum = availableAmount === undefined ? Number(freeMarginAmount.value) : availableAmount;

    return (
            <div className={classes.cashToWithdrawInfoText}>
                { withdrawLabelText 
                    ?? <R id='common_amount_available_to_withdraw' defaultValue='Amount available to withdraw'/> }
                <span>: </span>
                <span>
                {hideFiatCurrencyCodeForFirms ? format(sum) : format(sum, currency)}
                </span>
            </div>);
};

FreeMarginAmount.propTypes = {
    currency: PropTypes.string,
    availableAmount: PropTypes.number,
    withdrawLabelText: PropTypes.string,
    loadFreeMarginType: PropTypes.oneOf([LoadFreeMarginType.WITHDRAW, LoadFreeMarginType.TRANSFER]),
    finalPrecision: PropTypes.number
};

export default FreeMarginAmount;